
/*-----------------*/
/***** Apollo *****/
/*-----------------*/

figure.effect-apollo {
	overflow: hidden!important;
	margin:0;
}

figure.effect-apollo img {
	opacity: 0.95;
	-webkit-transition: opacity 0.1s, -webkit-transform 0.1s;
	transition: opacity 0.1s, transform 0.1s;
	-webkit-transform: scale3d(1.05,1.05,1);
	transform: scale3d(1.05,1.05,1);
}

figure.effect-apollo figcaption::before {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255,255,255,0.5);
	content: '';
	-webkit-transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
	-webkit-transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,-100%,0);
	transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,-100%,0);
}

figure.effect-apollo p {
	position: absolute;
	right: 0;
	bottom: 0;
	margin: 3em;
	padding: 0 1em;
	max-width: 150px;
	border-right: 4px solid #fff;
	text-align: right;
	opacity: 0;
	-webkit-transition: opacity 0.35s;
	transition: opacity 0.35s;
}

figure.effect-apollo h2 {
	text-align: left;
	margin:0;
	display:block;
	position: absolute;
	top:45%;
	left:0%;
	right:0%;
	background: rgba(0,0,0,0.61)!important;
	width:100%;
	color:rgba(255,255,255,1);
	font-size:15px;
	font-weight: bold;
	text-align: center;
	height:30px;
	line-height: 30px;
}

figure.effect-apollo:hover img {
	opacity: 0.6;
	-webkit-transform: scale3d(1,1,1);
	transform: scale3d(1,1,1);
	margin: -3px 0 0 0;

}

figure.effect-apollo:hover figcaption::before {
	-webkit-transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,100%,0);
	transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,100%,0);
}

figure.effect-apollo:hover p {
	opacity: 1;
	-webkit-transition-delay: 0.1s;
	transition-delay: 0.1s;
}

76060650001

0760650001 ovidiu