
            #maininner {

                -webkit-box-sizing: border-box;

                -moz-box-sizing: border-box;

                box-sizing: border-box;

            }

            #content {

                color:rgba(204, 204, 204, 1)!important;

                border: 1px solid rgba(255, 255, 255, 0.1)!important;

                -webkit-box-sizing: border-box;

                -moz-box-sizing: border-box;

                box-sizing: border-box;

            }

            #sidebar-a {

                margin:2px 0px 0px 0px;

                color:rgba(255, 255, 255, 1)!important;

                box-sizing:border-box;

                -webkit-box-sizing:border-box;

            }

            #sidebar-a div {

                color:rgba(255, 255, 255, 1)!important;

                /*font-family:'Oleo Script', cursive;*/

            }

            #headerbar, #footer, .mod-plain a {

                color: rgba(255, 255, 255, 1);

            }

            /*header------------------------------------*/

            #headerbar {

            }

            #menublock {

                /*    background: none!important;
            */

                border: none!important;

                margin:0px!important;

                padding:0px!important;

                /*    font-family:'Oleo Script', cursive!important;
            */

            }

            /*menu----------------------------*/

            #menubar {

                display: block;

                z-index: 1;

                height:50px!important;

                background: url(../../images/diverse/menubar_red10.png) 0 -5px repeat !important;

            }

            /*logo-----------------*/

            .logo {

                display: block;

                position: relative;

                width:220px;

                height:50px;

                top: 0px;

                z-index: 5;

                background:url('/templates/as5/imagesmin/logo/logo.gif') 50% 50% no-repeat!important;

                background-size: auto 128px !important;

            }

            .logo-bg {

                display: block;

                width:220px;

                height:50px;

                position: relative;

                z-index: 3;

                background: rgba(97, 11, 11, 1);

                background: -moz-radial-gradient(center, ellipse cover, rgba(97, 11, 11, 1) 0%, rgba(239, 1, 124, 0) 100%);

                background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(97, 11, 11, 1)), color-stop(100%, rgba(239, 1, 124, 0)));

                background: -webkit-radial-gradient(center, ellipse cover, rgba(97, 11, 11, 1) 0%, rgba(239, 1, 124, 0) 100%);

                background: -o-radial-gradient(center, ellipse cover, rgba(97, 11, 11, 1) 0%, rgba(239, 1, 124, 0) 100%);

                background: -ms-radial-gradient(center, ellipse cover, rgba(97, 11, 11, 1) 0%, rgba(239, 1, 124, 0) 100%);

                background: radial-gradient(ellipse at center, rgba(97, 11, 11, 1) 0%, rgba(239, 1, 124, 0) 100%);

                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#610b0b', endColorstr='#ef017c', GradientType=1);

                background-repeat: no-repeat;

                background-position: 50% 50%;

                background-size: 100px auto;

            }

            /*k2--------------*/

            #k2Container {

                /*   background: rgba(0, 0, 0, 0.61) !important;*/

                /*    box-shadow:0px 0px 10px 0px rgba(255, 255, 255, 1);
            */

                height:auto!important;

                padding:0px 0px 0px 0px;

                /*    background: rgb(0, 0, 0);
            */

            }

            .subCategory {

            }

            /*double lines*/

            .double-line-top {

                display:block;

                position: absolute;

                top:-1px;

                width:100%;

                height:0px;

                border-bottom:1px solid rgba(255, 255, 255, 0.4);

                border-top:1px solid rgba(0, 0, 0, 0.2);

            }

            .double-line-bottom {

                display:block;

                position: absolute;

                bottom:-1px;

                width:100%;

                height:0px;

                border-top:1px solid rgba(255, 255, 255, 0.4);

                border-bottom:1px solid rgba(0, 0, 0, 0.2);

            }

            .double-line-left {

                display:block;

                position: absolute;

                left:-1px;

                width:0px;

                height:100%;

                border-right:1px solid rgba(255, 255, 255, 0.4);

                border-left:1px solid rgba(0, 0, 0, 0.2);

            }

            .double-line-right {

                display:block;

                position: absolute;

                right:-1px;

                width:0px;

                height:100%;

                border-left:1px solid rgba(255, 255, 255, 0.4);

                border-right:1px solid rgba(0, 0, 0, 0.2);

            }

            /*animatii-------------------------*/

            /* contacte div----------------------- */

            .V1 #contacte {

                display: none;

                position: absolute;

                top:-5px;

                right:0px;

                height:80px!important;

                z-index: 2;

                width: 200px;

                /*        box-shadow: -1px 0px 0px 0px rgba(255, 255, 255, 0.2);
            */

                color: rgba(255, 255, 255, 1);

                text-align: center;

                /*    padding:10px 0 0  0 ;
            */

            }

            .V1 #contacte div {

                display:block;

                width:100%;

                font-family:"Trebuchet MS", Verdana, Arial, sans-serif;

                text-shadow:1px 2px 0px rgba(0, 0, 0, 1);

                -webkit-text-shadow:1px 2px 0px rgba(0, 0, 0, 1);

                font-size: 15px;

                /*   background: rgba(50, 0, 0, 0.5)*/

            }

            .V1 #contacte > div:nth-of-type(1) {

                background: radial-gradient(ellipse farthest-corner at 50% top, rgba(250, 0, 0, 0.215), rgba(20, 20, 20, 0.5) 100%), rgba(40, 3, 3, 1);

                border-top: 0.95px solid red;

                width: 100%;

                height:24.5px;

                overflow:hidden;

                line-height: 26px;

            }

            .V1 #contacte > div:nth-of-type(1) i {

                width:15%;

                height:25px;

                float:left;

                line-height: 25px;

            }

            .V1 #contacte > div:nth-of-type(1) div {

                width:85%;

                float:left;

                height:25px;

            }

            .V1 #contacte > div:nth-of-type(2) {

                background: radial-gradient(ellipse farthest-corner at 50% top, rgba(250, 0, 0, 0.215), rgba(20, 20, 20, 0.5) 100%), rgba(40, 3, 3, 1);

                border-bottom: 0.5px solid red;

                display: block;

                width: 100%;

                height:24px;

                overflow:hidden;

                line-height:20px;

            }

            .V1 #contacte > div:nth-of-type(2) i {

                width:15%;

                height:25px;

                float:left;

                line-height:20px;

            }

            .V1 #contacte > div:nth-of-type(2) div {

                width:85%;

                float:left;

            }

            .V1 #contacte > div:nth-of-type(3) {

                display: block;

            }

            .V1 #contacte > div:nth-of-type(3) i {

                background-size:100% 100%;

                font-size: 21px;

                height:30px;

                line-height: 30px!important;

                text-indent: 0;

            }

            /* contacte div v2----------------------- */

            .V2 #contacte {

                width: 100%;

                /*        box-shadow: -1px 0px 0px 0px rgba(255, 255, 255, 0.2);
            */

                color: rgba(255, 255, 255, 1);

                /*    background: radial-gradient(ellipse farthest-corner at 50% top, rgba(250, 0, 0, 0.215), rgba(20, 20, 20, 0.5) 100%);
            */

                /*    box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.41), 0px -2px 0px 0px rgba(255, 255, 255, 0.21);
            */

            }

            .V2 #contacte div {

                display:block;

                width:100%;

                font-family:'Open Sans', sans-serif;

                font-size: 13px;

                color: rgba(233, 233, 233, 1)!important;

                text-shadow:1px 2px 0px rgba(0, 0, 0, 1);

                -webkit-text-shadow:1px 2px 0px rgba(0, 0, 0, 1);

                letter-spacing: 0.5px;

                /*   background: rgba(50, 0, 0, 0.5)*/

            }

            .V2 #contacte > div:nth-of-type(1) {

                width: 100%;

                overflow:hidden;

                line-height: 23px;

            }

            .V2 #contacte > div:nth-of-type(1) i {

                width:15%;

                float:left;

                line-height: 23px;

                font-size: 17px;

                text-align: center;

            }

            .V2 #contacte > div:nth-of-type(1) div {

                width:85%;

                float:left;

            }

            .V2 #contacte > div:nth-of-type(2) {

                display: block;

                width: 100%;

                overflow:hidden;

                line-height:23px;

            }

            .V2 #contacte > div:nth-of-type(2) i {

                width:15%;

                float:left;

                font-size: 17px;

                line-height:23px;

                text-align: center;

            }

            .V2 #contacte > div:nth-of-type(2) div {

                /*    text-indent:-55px;
            */

                width:85%;

                float:left;

            }

            .V2 #contacte > div:nth-of-type(3) {

                display: none;

            }

            .V2 #contacte > div:nth-of-type(3) i {

                background-size:100% 100%;

                font-size: 21px;

                height:30px;

                line-height: 30px!important;

                text-indent: 0;

            }

            /*-----------------------------contact---------------------------------------
            */

            #Contact {

                box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.21);

                position: relative;

                display:block;

                padding: 0px 5px;

                font-size: 13px;

                color:rgba(255, 255, 255, 1);

                text-shadow:0px 1px 0px rgba(0, 0, 0, 1);

            }

            #Contact p {

                margin:4px;

            }

            #Contact a {

                color:rgba(255, 255, 255, 1);

            }

            #Contact .detalii h2 {

                font-size: 14px;

                font-weight: 600;

                margin:0px;

                background: rgba(255, 255, 255, 0.051);

                border-bottom: 1px solid rgba(160, 31, 21, 0.51);

                color:rgba(255, 255, 255, 1);

                height: 30px;

                line-height: 30px;

            }

            #Contact .detalii {

                /*dreapta*/

                width:50%;

                font-family:'Open Sans', sans-serif;

                /*    border: 1px solid rgba(55, 0, 0, 1);*/

                text-align: left;

                float: right;

                display:block;

            }

            #Contact .detalii .adresa {

                width: 100%;

                float: left;

                display:block;

            }

            #Contact .detalii .telefon {

                width: 100%;

                float: left;

                display:block;

            }

            #Contact .formular {

                /*stanga*/

                width:50%;

                font-family:'Open Sans', sans-serif;

                /*    border: 1px solid rgba(55, 0, 0, 1);*/

                text-align: left;

                float: right;

                display:block;

            }

            #Contact  h2 {

                font-size: 14px;

                font-weight: 600;

                margin:0px;

                background: rgba(255, 255, 255, 0.051);

                border-bottom: 1px solid rgba(160, 31, 21, 0.51);

                color:rgba(255, 255, 255, 1);

                height: 30px;

                line-height: 30px;
                text-indent: 10px;

            }

            #map_canvas {

                margin:0px 0px 0px 0px;

                width:100%;

                height:230px;

                background:#ccc;

                position: relative;

                display: block;

            }

            bold {

                font-weight: 600;

            }

            .fixed {

                display:block;

                position: fixed;

            }

            .despre_noi {

                border-top:1px solid rgba(255, 255, 255, 0.1);

                border-bottom:1px solid rgba(255, 255, 255, 0.1);

                -webkit-box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 1), 0px -1px 0px 0px rgba(0, 0, 0, 1), 1px 0px 0px 0px rgba(0, 0, 0, 1), 1px 0px 0px 0px rgba(0, 0, 0, 1);

                box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 1), 0px -1px 0px 0px rgba(0, 0, 0, 1), 1px 0px 0px 0px rgba(0, 0, 0, 1), -1px 0px 0px 0px rgba(0, 0, 0, 1);

                margin:5px;

                @-webkit-keyframes fadeInDown {

                    0% {

                        opacity: 0;

                        -webkit-transform: translateY(-20px);

                        transform: translateY(-20px);

                    }

                    100% {

                        opacity: 1;

                        -webkit-transform: translateY(0);

                        transform: translateY(0);

                    }

                }

                @keyframes fadeInDown {

                    0% {

                        opacity: 0;

                        -webkit-transform: translateY(-20px);

                        -ms-transform: translateY(-20px);

                        transform: translateY(-20px);

                    }

                    100% {

                        opacity: 1;

                        -webkit-transform: translateY(0);

                        -ms-transform: translateY(0);

                        transform: translateY(0);

                    }

                }

                .fadeInDown {

                    -webkit-animation-name: fadeInDown;

                    animation-name: fadeInDown;

                }

                padding:5px 0 5px 0;

            }

            .despre_noi p {

                margin:0 0 4px 0;

            }

            .despre_noi .poza_despre {

                display: block;

                box-shadow: 0px 0px 10px 5px rgba(255, 255, 255, 0.21);

                width: 30%;

                height:180px!important;

                float: left;

                margin:15px 0 0 10px;

                border:1px solid rgba(255, 255, 255, 0.25);

                -webkit-box-sizing: border-box;

                -moz-box-sizing: border-box;

                box-sizing: border-box;

            }

            .despre_noi .text {

                -webkit-box-sizing: border-box;

                -moz-box-sizing: border-box;

                box-sizing: border-box;

                width: 67%;

                display: block;

                margin:0 0 0 5px;

                float: right;

                color: rgba(255, 255, 255, 1);

                background: rgba(0, 0, 0, 0.3);

                padding:0px 5px 5px 5px;

            }

            .infoText {

                padding:5px;

                height:inherit;

            }

            .infoText div.guitars_pic {

                width:20%;

                height: 100px;

                float:left;

                margin:5px;

                box-shadow: 0px 0px 10px 5px rgba(255, 255, 255, 0.21)

            }

            .infoText div.drums_pic {

                width:20%;

                height:100px;

                float:left;

                margin:5px;

                box-shadow: 0px 0px 10px 5px rgba(255, 255, 255, 0.21)

            }

            .infoText div.bass_pic {

                width:20%;

                height:100px;

                float:left;

                margin:5px;

                box-shadow: 0px 0px 10px 5px rgba(255, 255, 255, 0.21)

            }

            .infoText div.guitbass_pic {

                width:20%;

                height:100px;

                float:left;

                margin:5px;

                box-shadow: 0px 0px 10px 5px rgba(255, 255, 255, 0.21)

            }

            .infoText div.perc_pic {

                display: block;

                width:20%;

                height:100px;

                float:left;

                margin:5px;

                box-shadow: 0px 0px 10px 5px rgba(255, 255, 255, 0.21)

            }

            .infoText div.keys_pic {

                display: block;

                width:20%;

                height:100px;

                float:left;

                margin:5px;

                box-shadow: 0px 0px 10px 5px rgba(255, 255, 255, 0.21)

            }

            .infoText .text {

                width:75%;

                float:right;

                margin:5px 10px 10px 10px;

            }

            .container_formatii {

                /*background:url('/templates/as5/imagesmin/formatii2.jpg') no-repeat!important;*/

                background-image: -moz-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0) 0%, rgba(20, 20, 20, 0) 50%, rgba(246, 41, 12, 0) 51%, rgba(245, 42, 14, 0) 55%, rgba(25, 25, 25, 0.36) 71%, rgba(10, 10, 10, 1) 100%), url('/templates/as5/imagesmin/formatii2.jpg') !important;

                background-image: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0) 0%, rgba(20, 20, 20, 0) 50%, rgba(246, 41, 12, 0) 51%, rgba(245, 42, 14, 0) 55%, rgba(25, 25, 25, 0.36) 71%, rgba(10, 10, 10, 1) 100%), url('/templates/as5/imagesmin/formatii2.jpg') !important;

                background-size: cover;

                padding:0!important;

            }

            .container_formatii > div:nth-child(1) {

                background: rgba(0, 0, 0, 0.51);

                background-clip: border-box;

                border-bottom: 1px solid rgba(0, 0, 0, 0.81);

                box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.05);

                font-size: 13px;

                font-weight: normal;

                text-indent: 10px;

                text-align: center;

                line-height: 20px;

                padding:5px;

                font-weight: bold;

                margin:1px 1px 1px 1px;

                border-radius: 10px 10px 0 0;

            }

            .container_formatii > div:nth-child(2) {

                background: rgba(0, 0, 0, 0.51);

                height:130px;

                line-height: 130px;

                text-align: center;

                font-size: 20px!important;

                font-weight: bold;

                text-shadow:0px 1px 10px rgba(222, 0, 0, 1)!important;

                border-radius: 0 0 5px 5px;

                margin:0px 1px 1px 1px;

            }

            /*despre noi page*/

            .despre {

                width:100%;

                -webkit-box-sizing: border-box;

                -moz-box-sizing: border-box;

                box-sizing: border-box;

            }

            .despre .poza {

                display:block;

                position: relative;

                width:400px;

                height:300px;

                left:25%;

                right:25%;

                box-shadow: 0px 0px 10px 5px rgba(255, 255, 255, 0.21);

            }

            .despre .text {

                display:block;

                -webkit-box-sizing: border-box;

                -moz-box-sizing: border-box;

                box-sizing: border-box;

                margin:10px;

                border:1px solid rgba(255, 255, 255, 0.1);

                padding:5px;

            }

            .despre p {

                padding:0px 0px 0px 0px;

                margin:0;

                text-align: left;

            }

            .info-icon {

                width:52.5px;

                height:53.8px;

                background: url('/templates/as5/imagesmin/icons/info.gif') 0 0 no-repeat !important;

                background-size: 50px!important;

                float:left;

                line-height: 0;

                margin:0 5px 0 0;

            }

            .echipamente {

                /*text*/

                color: rgba(255, 255, 255, 1);

                position: relative;

                display: block;

                padding:5px;

                margin:20px 10px 20px 10px;

                /* font-family: 'Oleo Script', cursive; */

                text-shadow: 0px 2px 0px rgba(0, 0, 0, 1);

                /* background: rgba(120, 14, 0,0.51); */

                /* color: rgb(147, 127, 103); */

                /*                background: none repeat scroll 0% 0% rgba(255, 255, 255, 0.1);
*/

                color: rgba(255, 255, 255, 1);

            }

            /*categorie contact v2*/

            .harta {

            }

            .harta h2 {



            }
