html {
    box-sizing:border-box;
    -webkit-box-sizing:border-box
}
#Container1 {
    margin: 5px;
    color: rgba(255, 255, 255, 1);
/*    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);*/
    background: rgba(0, 0, 0, 0.1);
}
/*bara titluri*/
 .top_title {
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
/*    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
*/    height:25px;
    line-height: 25px;
    color: rgba(255,255,255,0.71);
    font-weight: 400;
        background: rgba(255,255,255,0.051);
box-shadow: 0px 1px 0px 0px rgba(120, 14, 0,0.21);
}
.top_title div {
    float:left;
    text-align: center
}
.top_title div:nth-child(1) {
    width:15%
}
.top_title div:nth-child(2) {
    width:33%
}
.top_title div:nth-child(3) {
    width:17%
}
.top_title div:nth-child(4) {
    width:34%
}
/*---------------------------------------------------------*/
 .cat_title1 {
    font-weight: 600;
    height:25px;
    line-height: 25px;
    text-indent: 20px;
    border-bottom: 1px solid rgb(45, 0, 0);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    font-size: 14px;
    margin:0px 0px 0px 0px;
    color:rgba(255,255,255,1);
    background: rgba(255,255,255,0.1);
}
/*container*/
 .container {
    width: 100%;
    text-transform: capitalize!important;
    color:rgba(235, 235, 235, 1);
}
.container .info {
    width:100%;
    text-align: center;
}
.container .info:nth-child(1) {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.container .info div {
    float:left;
/*    box-shadow: -3px 0px 10px  -5px rgba(255,255,255,1);
*/    overflow:
}
.container div div:nth-child(1) {
    width:15%;
    height:30px;
    line-height: 30px;
    text-align: center;
    font-weight:bold;
}
.container div div:nth-child(2) {
    width:33%;
    height:30px;
    line-height: 30px;
    text-align: center
}
.container div div:nth-child(3) {
    width:17%;
    height:30px;
    line-height: 30px;
    text-align: center
}
.container div div:nth-child(4) {
    width:34%;
    height:30px;
    line-height: 30px;
    text-align: center
}
