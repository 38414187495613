/**
 *  Master Slider
 *  Metro Skin
 */
 .ms-skin-metro .ms-slide .ms-slide-vpbtn, .ms-skin-metro .ms-video-btn {
    width: 47px;
    height: 46px;
    background: url(metro-skin.png) no-repeat -16px -152px;
    top: 50%;
    left: 50%;
    margin: -22px 0 0 -24px;
}
.ms-skin-metro .ms-slide .ms-slide-vcbtn {
    width: 47px;
    height: 47px;
    background: url(metro-skin.png) no-repeat -16px -19px;
    top: 15px;
    right: 15px;
}
/* controls */
 .ms-skin-metro .ms-nav-next, .ms-skin-metro .ms-nav-prev {
    width: 47px;
    background: url(metro-skin.png);
    background-position: -83px -23px;
    height: 47px;
    cursor: pointer;
    top: 50%;
    left: 30px;
    margin-top: -24px;
    position: absolute;
    z-index: 10;
}
.ms-skin-metro .ms-nav-next {
    right: 30px;
    left: auto;
    background-position: -81px -100px;
}
.ms-skin-metro .ms-thumb-frame-selected {
    opacity: 0.6;
}
.ms-skin-metro .ms-thumb-list.ms-dir-h {
    bottom: -60px;
    width: 100%;
    height: 60px;
}
.ms-skin-metro .ms-thumb-list.ms-dir-v {
    height: 100%;
    width: 60px;
    right: -60px;
    top: 0;
}
.ms-skin-metro .ms-thumb-frame {
    cursor: pointer;
    float: left;
    width: 60px;
    height: 60px;
    margin: 1px 0 0 1px;
    overflow: hidden;
}
.ms-skin-metro .ms-bullets.ms-dir-h {
    position: absolute;
    bottom: 20px;
    right: 50%;
    width: 100px;
}
.ms-skin-metro .ms-bullets.ms-dir-h .ms-bullets-count {
    position: relative;
    right: -50%;
}
.ms-skin-metro .ms-bullet {
    width: 16px;
    height: 16px;
    background: url(metro-skin.png) no-repeat;
    background-position: -31px -114px;
    margin: 4px;
}
.ms-skin-metro .ms-bullet-selected {
    background-position: -31px -81px;
}
.ms-skin-metro .ms-sbar {
    position: absolute;
}
.ms-skin-metro .ms-sbar.ms-dir-h {
    left: 10px;
    right: 10px;
    top: 5px
}
.ms-skin-metro .ms-sbar.ms-dir-v {
    top: 10px;
    bottom: 10px;
    right: 5px
}
.ms-skin-metro .ms-sbar .ms-bar {
    height: 4px;
    background: #DD00FF;
    border-radius: 4px;
}
.ms-skin-metro .ms-sbar.ms-dir-v .ms-bar {
    width: 4px;
}
.ms-skin-metro .ms-timerbar {
    width: 100%;
    bottom: 0px;
    position: absolute;
}
.ms-skin-metro .ms-time-bar {
    height: 3px;
    background: #FFFFFF;
    border-top: solid 1px rgba(0, 0, 0, 0.41);
}
.ms-skin-metro .ms-ctimer {
    position: absolute;
    top: 30px;
    left: 30px;
    cursor: pointer;
}
.ms-skin-metro .ms-ctimer-bullet {
}
.ms-skin-metro .ms-tooltip {
    position: absolute;
    z-index: 110;
    cursor: auto;
}
.ms-skin-metro .ms-tooltip-top .ms-tooltip-arrow, .ms-skin-metro .ms-tooltip-bottom .ms-tooltip-arrow {
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
    position: absolute;
    left: 50%;
    margin-left: -10px;
}
.ms-skin-metro .ms-tooltip-top .ms-tooltip-arrow {
    border-top: solid 10px;
    border-top-color: silver;
    border-top-color: rgba(240, 240, 240, 0.88);
    bottom: -10px;
}
.ms-skin-metro .ms-tooltip-bottom .ms-tooltip-arrow {
    border-bottom: solid 10px;
    border-bottom-color: silver;
    border-bottom-color: rgba(240, 240, 240, 0.88);
    top: -10px;
}
.ms-skin-metro .ms-tooltip-right .ms-tooltip-arrow, .ms-skin-metro .ms-tooltip-left .ms-tooltip-arrow {
    border-top: solid 10px transparent;
    border-bottom: solid 10px transparent;
    position: absolute;
    top: 50%;
    margin-top: -10px;
}
.ms-skin-metro .ms-tooltip-right .ms-tooltip-arrow {
    border-right: solid 10px;
    border-right-color: silver;
    border-right-color: rgba(240, 240, 240, 0.88);
    left: -10px;
}
.ms-skin-metro .ms-tooltip-left .ms-tooltip-arrow {
    border-left: solid 10px;
    border-left-color: silver;
    border-left-color: rgba(240, 240, 240, 0.88);
    right: -10px;
}
@keyframes point-anim {
    0% {
        transform: scale(0.5);
        -webkit-transform: scale(0.5);
        -moz-transform: scale(0.5);
        -o-transform: scale(0.5);
        -ms-transform: scale(0.5);
        opacity: 1;
    }
    100% {
        transform: scale(0.5);
        -webkit-transform: scale(0.5);
        -moz-transform: scale(1.5);
        -o-transform: scale(1.5);
        -ms-transform: scale(1.5);
        opacity: 0;
    }
}
@-webkit-keyframes point-anim {
    0% {
        -webkit-transform: scale(0.5);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1.5);
        opacity: 0;
    }
}
.ms-skin-metro .ms-tooltip-point {
    cursor: pointer;
}
.ms-skin-metro .ms-tooltip-point .ms-point-center {
    width: 12px;
    height: 12px;
    border-radius: 15px;
    border: solid 2px #BBB;
    background: white;
    margin: -8px 0 0 -8px;
    position: absolute;
    z-index: 5;
}
.ms-skin-metro .ms-tooltip-point .ms-point-border {
    width: 22px;
    height: 22px;
    background: black;
    background:rgba(0, 0, 0, 0.36);
    margin: -11px 0 0 -11px;
    position: absolute;
    border-radius: 15px;
    animation: point-anim 1.5s infinite;
    -moz-animation: point-anim 1.5s infinite;
    -webkit-animation: point-anim 1.5s infinite;
    -o-animation: point-anim 1.5s infinite;
}
/* In Android browser, using css animations over slider causes some crashes */
 .ms-android.ms-skin-metro .ms-tooltip-point .ms-point-border {
    animation:none;
    -moz-animation:none;
    -webkit-animation:none;
    -o-animation:none;
}
.ms-skin-metro .ms-tooltip {
    max-width:200px;
}
.ms-skin-metro .ms-tooltip-cont {
    padding: 10px;
    background: silver;
    background: rgba(240, 240, 240, 0.88);
    text-shadow: 0 1px 0px white;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.04);
}
/* retina */
 @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .ms-skin-metro .ms-slide .ms-slide-vpbtn, .ms-skin-metro .ms-video-btn, .ms-skin-metro .ms-slide .ms-slide-vcbtn, .ms-skin-metro .ms-nav-next, .ms-skin-metro .ms-nav-prev, .ms-skin-metro .ms-bullet {
        background-image:url(metro-skin-retina.png);
        background-size: 152px 225px;
    }
}
.ms-caption {
    display: block;
    bottom:0;
    width:98%;
    height:41.8%;
    margin:5px 1%;
    background: rgba(21, 21, 21, 0.651);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(255,255,255,0.2);
    box-shadow: 0px 0px 5px 0px rgba(255,255,255,0.2);

}
.ms-caption .left {
    width:7%;
    float:left;
    height:100%;
    background: rgba(120, 14, 0,1);
    background: -moz-linear-gradient(top, rgba(120, 14, 0,1) 0%, rgba(168, 30, 12, 1) 47%, rgba(120, 14, 0,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(120, 14, 0,1)), color-stop(47%, rgba(168, 30, 12, 1)), color-stop(100%, rgba(120, 14, 0,1)));
    background: -webkit-linear-gradient(top, rgba(120, 14, 0,1) 0%, rgba(168, 30, 12, 1) 47%, rgba(120, 14, 0,1) 100%);
    background: -o-linear-gradient(top, rgba(120, 14, 0,1) 0%, rgba(168, 30, 12, 1) 47%, rgba(120, 14, 0,1) 100%);
    background: -ms-linear-gradient(top, rgba(120, 14, 0,1) 0%, rgba(168, 30, 12, 1) 47%, rgba(120, 14, 0,1) 100%);
    background: linear-gradient(to bottom, rgba(120, 14, 0,1) 0%, rgba(168, 30, 12, 1) 47%, rgba(120, 14, 0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#780e00', endColorstr='#780e00', GradientType=0);
}
.ms-caption .right {
    width:90%;
    float:right;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}
.caption-title {
    margin:10px 10px 5px 10px;
    font-size: 17px;
    text-align:left;
    color:white;
    font-weight: bold;
    border-bottom:1px solid rgba(255,255,255,.051);
    -webkit-box-shadow: 0px 1px 0px 0px rgba(0,0,0,.81);
    box-shadow: 0px 1px 0px 0px rgba(0,0,0,.81);
    color:rgba(255,255,255,1);
    text-shadow:0px 0px 10px rgba(255,0,0,1);
}
.caption-content {
    /*background: rgba(21, 21, 21, 0.151);*/
    font-size: 13px;
    text-align:left;
    color:white;
        margin:0px 10px 5px 10px;
        padding:5px 10px 10px 10px ;

}
.ms-caption red {

    font-size: 17px;
    font-weight: bolder;
}
.ms-caption right {
    float:right;
    margin:10px;
}
.ms-caption a {
    color:rgba(255,255,255,1);
    text-shadow:0px 0px 10px rgba(255,0,0,1);
    font-size: 14px;
    text-decoration: underline;
    letter-spacing:0.5px;
}
.ms-caption a:hover {
    color:rgba(255, 255,255, .71);
    font-size: 14px;
        text-shadow:0px 0px 10px rgba(255,255,255,1);

}
