/* Copyright (C) YOOtheme GmbH, YOOtheme Proprietary Use License (http://www.yootheme.com/license) */

body#page { background: url('/templates/as5/imagesmin/background/bg_2.jpg') black  center center   no-repeat ;
	background-size: cover ;

		background-attachment: fixed!important;
 }

 @media screen and (max-width:  1300px ) {

 	body#page { background: url('/templates/as5/imagesmin/background/bg_2.jpg')  black  center center  no-repeat;
	background-size: cover	;
		background-attachment: fixed!important;
		background-position: -250px 0px;

 }



 }

