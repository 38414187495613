/* Copyright (C) YOOtheme GmbH, http://www.gnu.org/licenses/gpl.html GNU/GPL */
/*
 * Menus Style Sheet - Defines base styles for various menus
 * Supported: Mega Drop-Down Menu, Sidebar/Accordion Menu and Line Menu
 */
/* Menu Helpers
----------------------------------------------------------------------------------------------------*/
.menu,
.menu ul {
	margin: 0 0 0 0;
	padding: 0px;
	list-style: none;
}
ul.menu-sidebar {
	padding: 0px;
	list-style: none;
/*	background:radial-gradient(ellipse farthest-corner at 100% top , rgba(150, 52, 50, 0.15), rgba(20, 20, 20, 0.5) 100%) repeat scroll 0% 0% transparent;
*/}
.menu a,
.menu span { display: block; }
.menu a { text-decoration: none; }
/* Mega Drop-Down Menu
----------------------------------------------------------------------------------------------------*/
.menu-dropdown li { position: relative; }
/* Level 1 */
.menu-dropdown,
.menu-dropdown .level1,
.menu-dropdown .level1 > span { float: left; }
.menu-dropdown a.level1 > span,
.menu-dropdown span.level1 > span {
	height: 30px;
	padding: 0 10px;
	line-height: 30px;
}
/* Drop-Down */
.menu-dropdown .dropdown {
	display: none;
	position: absolute;
	top: 30px;
 	left: 0;
	z-index: 100;
	width: 250px!important; /* Default */
}
.menu-dropdown li:hover .dropdown,
.menu-dropdown li.remain .dropdown { display: block; }
.menu-dropdown li:hover .dropdown.flip,
.menu-dropdown li.remain .dropdown.flip {
	left: auto;
	right: 0;
}
.menu-dropdown .dropdown-bg > div { overflow: hidden; }
.menu-dropdown .stack .column { width: 100%; }
/* Multi Columns, Default */
.menu-dropdown .columns2 { width: 600px; }
.menu-dropdown .columns3 { width: 900px; }
.menu-dropdown .columns4 { width: 1200px; }
.menu-dropdown .column { float: left; }
/* Level 2 */
.menu-dropdown a.level2 > span,
.menu-dropdown span.level2 > span {
	padding: 7px 0;
	line-height: 21px;
	overflow: hidden;
}
/* Level 3 */
.menu-dropdown ul.level3 { padding-bottom: 10px; }
.menu-dropdown li.level3 { /* Must be set in li for IE7 */
	font-size: 11px;
	line-height: 17px;
}
/* Icons */
.menu-dropdown span.icon {
	display: block;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	float: left;
}
.menu-dropdown .level1 .level1 span.icon {
	width: 30px;
	height: 30px;
	margin-left: -5px;
}
.menu-dropdown .level1 .level1 span.icon + span,
.menu-dropdown .level1 .level1 span.icon + span + span { margin-left: 25px; }
.menu-dropdown li.level2 .level2 span.icon {
	width: 35px;
	height: 35px;
	margin-top: -7px;
	margin-bottom: -7px;
}
.menu-dropdown li.level2.hassubtitle .level2 span.icon { margin-top: -4px; }
.menu-dropdown li.level2 .level2 span.icon { margin-right: 5px; }
.menu-dropdown li.level2 .level2 span.icon + span,
.menu-dropdown li.level2 .level2 span.icon + span + span { margin-left: 40px; }
/* Subtitles */
.menu-dropdown span.title { line-height: 18px; }
.menu-dropdown span.subtitle {
	margin-top: -1px;
	font-size: 9px;
	line-height: 10px;
	text-indent: 1px;
}
.menu-dropdown li.level2 .level2 span.title { line-height: 15px; }
.menu-dropdown li.level2 .level2 span.subtitle { line-height: 14px; }
/* Sidebar/Accordion Menu
----------------------------------------------------------------------------------------------------*/
/* Accordion */
.menu-sidebar li.parent span.level1 > span { cursor: pointer; }
/* Level 1 */
.menu-sidebar a.level1 > span,
.menu-sidebar span.level1 > span {
/*	padding: 5px 0;
	line-height: 20px;*/
}
/* Remove Subtitles */
.menu-sidebar span.title { display: inline; }
.menu-sidebar span.subtitle { display: none; }
/* Level 2-4 */
.menu-sidebar ul.level2 { padding-bottom: 10px; }
.menu-sidebar li.level1:last-child ul.level2 { padding-bottom: 0; }
.menu-sidebar .level2 a > span {
/*	font-size: 11px;
	line-height: 17px;*/
}
/* Icons */
.menu-sidebar span.icon {
	display: inline-block;
	height: 30px;
	width: 30px;
	margin: -5px 5px -5px 0;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	vertical-align: middle;
}
/* Line Menu
----------------------------------------------------------------------------------------------------*/
/* Level 1 */
.menu-line,
.menu-line li,
.menu-line li a { display: inline-block; }
