/* Copyright (C) YOOtheme GmbH, YOOtheme Proprietary Use License (http://www.yootheme.com/license) */






/*group hack*/

.group:before,
.group:after {
    content: "";
    display: table;
}
.group:after {
    clear: both;
}
.group {
    zoom: 1; /* For IE 6/7 (trigger hasLayout) */
}

/* New style-related Base Values
----------------------------------------------------------------------------------------------------*/

body { font-size:13px;
font-family: 'Open Sans', sans-serif;
}

h1, h2, h3, h4, h5 {
	text-transform: uppercase;
}

h1 { font-size: 28px; line-height: 28px; }
h2 { font-size: 24px; line-height: 24px; }
h3 { font-size: 17px; line-height: 18px; }
h4 { font-size: 16px; line-height: 16px; }
h5 { font-size: 14px; line-height: 14px; }

h2,
h5 { color: #444; }

small { color: #aaa; }

hr { border-top-color: #e2e2e2; }

pre {
	border: 1px solid #e2e2e2;
	background: #fafafa;
}


/* Useful Snippets
----------------------------------------------------------------------------------------------------*/

a:not([class]):active,
a:not([class]):focus {
	position: relative;
	top: 1px;
}

a:not([class]):active > *,
a:not([class]):focus > * {
	position: relative;
	top: -1px;
}