/**
 *  Master Slider
 *	version 1.5
 * 	Core css file
 */


.master-slider{
	position: relative;
	visibility: hidden;
	-moz-transform: translate3d(0 , 0 , 0);
}

.master-slider.ms-fullheight{height: 100%;}

.master-slider.before-init{}

.master-slider img {max-width:none;}

.ms-ie img{border:none;}
.ms-ie8 * {opacity:inherit;filter:inherit;}
.ms-ie7   {zoom:1;}

.ms-loading-container{
	position:absolute;
	width:100%;
	top:0;
}

.ms-loading-container .ms-loading,
.ms-slide .ms-slide-loading {
	width: 36px;
	height: 36px;
	background: url(loading-2.gif) no-repeat center white;
	border-radius: 60px;
	box-shadow: 0px 0px 3px rgba(0,0,0,0.2);
	position: absolute;
	left: 50%;
	top: 50%;
	margin: -18px;
	opacity: 0.9;
}


/* grab cursor */
.ms-def-cursor{cursor:auto !important;}
.ms-grab-cursor{cursor: url("grab.png"), move;}
.ms-grabbing-cursor{cursor: url("grabbing.png"), move;}
.ms-ie .ms-grab-cursor{cursor: url("../masterslider/style/grab.cur"), move;}
.ms-ie .ms-grabbing-cursor {cursor: url("../masterslider/style/grabbing.cur"), move;}

.ms-view {
	overflow:hidden;
	background:black;
	position:relative;
	-webkit-perspective: 2000px;
	-moz-perspective: 2000px;
	-ms-perspective: 2000px;
	perspective: 2000px;
}

.ms-view .ms-slide-container{position:relative;}

.ms-slide {
	position:absolute;
	-webkit-transform: translateZ(0px);
	-moz-transform: translateZ(0px);
	-ms-transform: translateZ(0px);
	-o-transform: translateZ(0px);
	transform: translateZ(0px);

	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	-ms-transform-style: preserve-3d;
	transform-style: preserve-3d;

	height:100%;
}

.ms-slide.selected {}

.ms-slide .ms-slide-bgcont,
.ms-slide .ms-slide-bgvideocont{
	position: absolute;
	overflow:hidden;
	width: 100%;
}

.ms-slide .ms-slide-bgvideocont{
	z-index: 5;
	height: 100%;
}

.ms-slide .ms-slide-bgvideocont,
.ms-slide-bgvideocont>video{
	-webkit-transform: translateZ(0.3px);
	-moz-transform: translateZ(0.3px);
	-ms-transform: translateZ(0.3px);
	-o-transform: translateZ(0.3px);
	transform: translateZ(0.3px);
}

.ms-slide .ms-slide-bgvideo{
	position: absolute;
	top: 0px;
}


.ms-container {position: relative; margin:0 auto;}
.ms-slide .ms-slide-bgcont img , .ms-container{
	-webkit-user-select:none;
	-moz-user-select:none;
	-ms-user-select:none;
	user-select:none;
}

.ms-slide .ms-layer{position:absolute;}

.ms-slide .ms-slide-layers{
	position: absolute;
	overflow:hidden;
	width:100%;
	height: 100%;
	z-index: 10;
	top:0px;
}

.ms-slide .ms-slide-video{
	position:absolute;
	top:0;
	z-index: 11;
	background:black;
	padding:0;
	margin: 0;
	border: none;

}

.ms-layer.video-box{background:black;}
.ms-layer.video-box iframe{
	padding:0;
	margin: 0;
	border: none;
	position: absolute;
	z-index: 10;
}

.ms-slide .ms-slide-vpbtn , .ms-slide .ms-video-btn{
	position:absolute;
	cursor: pointer;
	z-index: 10;
}

.ms-slide .ms-slide-vcbtn{
	position:absolute;
	cursor: pointer;
	z-index: 13;
}

.ms-slide-vcbtn-mobile{
	position: relative;
	bottom: 0;
	left:0;
	width:100%;
	height: 28px;
	background-color: black;
	color: white;
	text-align: center;
	cursor: pointer;
	z-index: 13;
}

.ms-slide-vcbtn-mobile .ms-vcbtn-txt {
    text-transform: uppercase;
    font-family: sans-serif;
    font-size: 0.75em;
    display: inline-block;
    background: url(video-close-btn.png) no-repeat 0px 3px;
    padding-left: 15px;
    height: 15px;
    color: #DBDBDB;
    margin-top: 7px;
}

.ms-slide .ms-slide-vcbtn,
.ms-slide .ms-slide-layers,
.ms-slide .ms-slide-vpbtn,
.ms-slide .ms-slide-video {
	-webkit-transform: translateZ(0.44px);
	-moz-transform: translateZ(0.44px);
	-ms-transform: translateZ(0.44px);
	-o-transform: translateZ(0.44px);
	transform: translateZ(0.44px);
}

.ms-video-img{
	position:absolute;
	width:100%;
	height:100%;
	top:0;
	left:0;
}

.ms-mask-frame{
	overflow:hidden;
	position:absolute;
	float:left;
}

/* controls */

.ms-thumb-list{
	overflow:hidden;
	position:absolute;
}
.ms-thumbs-cont{position: relative;}
.ms-thumb-frame img{width:100%;	height:auto;}
.ms-thumb-frame{
	cursor:pointer;
	float: left;
	overflow: hidden;
}

.ms-bullet{cursor:pointer;float: left;}

.ms-sbar .ms-bar{
	position:relative;
	-webkit-transition: opacity 300ms;
	-moz-transition: opacity 300ms;
	-ms-transition: opacity 300ms;
	-o-transition: opacity 300ms;
	transition: opacity 300ms;
}

.ms-time-bar{
	-webkit-transition: width 120ms linear;
	-moz-transition: width 120ms linear;
	-ms-transition: width 120ms linear;
	-o-transition: width 120ms linear;
	transition: width 120ms linear;

}

.ms-ie7 .ms-tooltip-arrow{
	height:0px;
}
