/* Copyright (C) YOOtheme GmbH, YOOtheme Proprietary Use License (http://www.yootheme.com/license) */

/* Mega Drop-Down Menu
----------------------------------------------------------------------------------------------------*/

/* Level 1 */
 .menu-dropdown li.level1 {
    background: url(../images/menu_dropdown_level1.png) 100% 50% no-repeat;
    /*    font-family:'Oleo Script', cursive!important;
*/
    /*    font-family:'Open Sans', sans-serif;
*/
    color: rgba(255, 255, 255, 1);
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 1)!important;
    font-size: 15px;
}
.menu-dropdown li.level1:first-child {
    background: url(../images/menu_dropdown_level1.png) 0% 50% no-repeat, url(../images/menu_dropdown_level1.png) 100% 0% no-repeat;
}
.menu-dropdown a.level1, .menu-dropdown span.level1 {
    font-size: 14px;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.5);
    font-weight: bold;
}
.menu-dropdown a.level1 > span, .menu-dropdown span.level1 > span {
    height: 50px;
    line-height: 50px;
    text-transform: uppercase;
    color:rgba(255, 255, 255, 1);
}
/* Set Hover */
 .menu-dropdown li.level1:hover .level1, .menu-dropdown li.level1:hover li.active, .menu-dropdown li.remain .level1 {
    color:rgba(255, 222, 222, 1);
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 1), 1px 1px 0px rgba(0, 0, 0, 1);
    font-weight: bold;
    font-size: 14px;
    -webkit-animation: fade 0.1s;
    animation: fade 0.1s;
    /*-webkit-box-shadow: inset 0px 66px 50px -70px rgba(255,255,255,1);
-moz-box-shadow: inset 0px 66px 50px -70px rgba(255,255,255,1);
box-shadow: inset 0px 66px 50px -70px rgba(255,255,255,1);*/
    box-shadow:inset 0px 0px 4px 2px rgba(255, 0, 0, 0.1);
}
/* Set Active */
 .menu-dropdown li.active .level1 {
    font-weight: bold!important;
    font-size: 14px!important;
    text-shadow: 1px 2px 0px rgba(0, 0, 0, 1), 1px 2px 0px rgba(0, 0, 0, 1);
    -webkit-text-shadow: 1px 1px 0px rgba(0, 0, 0, 1), 1px 1px 0px rgba(0, 0, 0, 1);
    color: rgba(255, 255, 255, 1)!important;
    border-right:1px solid rgba(0, 0, 0, 0.1);
}
/* Drop-Down */
 .menu-dropdown .dropdown {
}
.menu-dropdown .dropdown-bg > div {
    padding: 0;
    /*    border: 1px solid rgba(0, 0, 0, 0.15);
    border-bottom-color: rgba(0, 0, 0, 0.25);*/
    background: rgb(120, 14, 0);
    background-clip: padding-box;
    color:rgba(255, 255, 255, 1);
    margin-top: 15px;
    background: rgba(150,28,24,1);
background: -moz-linear-gradient(top, rgba(150,28,24,1) 0%, rgba(128,21,12,1) 54%, rgba(120,18,8,1) 75%, rgba(110,15,2,1) 100%);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(150,28,24,1)), color-stop(54%, rgba(128,21,12,1)), color-stop(75%, rgba(120,18,8,1)), color-stop(100%, rgba(110,15,2,1)));
background: -webkit-linear-gradient(top, rgba(150,28,24,1) 0%, rgba(128,21,12,1) 54%, rgba(120,18,8,1) 75%, rgba(110,15,2,1) 100%);
background: -o-linear-gradient(top, rgba(150,28,24,1) 0%, rgba(128,21,12,1) 54%, rgba(120,18,8,1) 75%, rgba(110,15,2,1) 100%);
background: -ms-linear-gradient(top, rgba(150,28,24,1) 0%, rgba(128,21,12,1) 54%, rgba(120,18,8,1) 75%, rgba(110,15,2,1) 100%);
background: linear-gradient(to bottom, rgba(150,28,24,1) 0%, rgba(128,21,12,1) 54%, rgba(120,18,8,1) 75%, rgba(110,15,2,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#961c18', endColorstr='#6e0f02', GradientType=0 );
}
.menu-dropdown .dropdown .module {
}
/* Level 2 */
 .menu-dropdown .column ul.level2 {
}
.menu-dropdown .column:first-child ul.level2 {
    border-left: none;
}
.menu-dropdown li.level2 {
}
.menu-dropdown li.level2:first-child {
}
.menu-dropdown a.level2, .menu-dropdown span.level2 {
    font-size: 13px;
    text-transform: uppercase;
    color: #fff;
    text-indent: 10px;
}
.menu-dropdown a.level2 > span, .menu-dropdown span.level2 > span {
    font-weight: bold;
    text-shadow: 0px 2px 0px rgba(0, 0, 0, 1)!important;
    border-bottom: 1px solid rgba(100, 0, 0, 1);
    box-shadow: 0px -1px 0px 0px rgba(255, 255, 255, 0.05);
    margin:1px;
}
/* Set Current */
 .menu-dropdown a.current.level2 {
    font-weight: bold;
    text-shadow: 0px 2px 0px rgba(0, 0, 0, 1)!important;
    text-indent: 10px;
}
/* Level 3 */
 .menu-dropdown a.level3 {
    padding-left: 12px;
    color: #666;
}
/* Set Hover */
 .menu-dropdown a.current.level2:hover, .menu-dropdown a.level2 span:hover {
    font-weight: bold;
    text-shadow: 0px 2px 0px rgba(0, 0, 0, 1)!important;
    color:rgba(255, 255, 255, 1);
    text-decoration: underline;
}
.menu-dropdown a.level3:hover {
    background-position: 0 -50px;
}
/* Set Current */
 .menu-dropdown a.current.level3 {
    font-weight: bold;
}
/* Subtitles */
 .menu-dropdown span.subtitle {
    font-size: 12px;
    text-transform: none;
}
.menu-dropdown .level1 .level1 span.title {
    line-height: 18px;
}
.menu-dropdown .level1 .level1 span.subtitle {
    margin-top: 0;
}
.menu-dropdown .level2 .level2 span.subtitle {
    color: #888;
}
/* Sidebar/Accordion Menu
----------------------------------------------------------------------------------------------------*/

/* Link */
 .menu-sidebar a, .menu-sidebar li > span {
    color: rgba(255, 255, 255, 1);
    font-size: 13px;
    /*    font-family:'Oleo Script', cursive;*/
    height:30px!important;
    line-height: 30px!important;
    border-top:1px solid rgba(0, 0, 0, 1);
    box-shadow: 0px -1px 0px 0px rgba(255, 255, 255, 0.05);
    /* background: radial-gradient(ellipse farthest-corner at 00% top, rgba(250, 0, 0, 0.115), rgba(20, 20, 20, 0.5) 100%), rgba(0, 0, 0, 1);*/

}
.mod-box-black .menu-sidebar a, .mod-box-black .menu-sidebar li > span {


}
.menu-sidebar li.level1 > a > span, .menu-sidebar li.level1 > span > span {
    text-transform: uppercase;
    height:30px!important;
    border-left:8px solid rgba(15,15,15,1);
    line-height: 30px!important;
    text-indent: 10px;
}
/* Hover */
 .menu-sidebar li.level1:hover > a > span, .menu-sidebar li.level1.active > a > span, .mod-box-color .menu-sidebar a:hover, .mod-box-color .menu-sidebar li > span:hover, .mod-box-color .menu-sidebar li.level1.active > a > span, .mod-box-color .menu-sidebar li.level1.active > span > span, .mod-box-black .menu-sidebar li.level1.active > a > span, .mod-box-black .menu-sidebar li.level1.active > span > span {
    background: rgba(255, 255, 255, 0.1);
    height:30px;
    line-height: 30px;
    /*    text-shadow: 0px 3px 0px rgba(0, 0, 0, 0), 0px 3px 0px rgba(0, 0, 0, 1);*/
    /*text-shadow: -1px 3px 0px rgba(0, 0, 0, 0), -1px 3px 0px rgba(0, 0, 0, 1);*/
    color:rgba(255,255,255,1);
    border-left:8px solid rgb(120, 14, 0);
}
.mod-box-black .menu-sidebar a:hover, .mod-box-black .menu-sidebar li > span:hover {
    color: rgba(255,255,255,1);
}
/* Active */
 .menu-sidebar li.level1.active > a > span, .menu-sidebar li.level1.active > span > span {
    text-indent: 15px;
    text-decoration: none;

}
/* Current */
 .menu-sidebar a.current {
    text-shadow: -1px 3px 0px rgba(0, 0, 0, 0), -1px 3px 0px rgba(0, 0, 0, 1);
}
/* Level 1 */
 .menu-sidebar li.level1 {
}
.menu-sidebar li.level1:hover {
}
.mod-box-black .menu-sidebar li.level1 {
}
.menu-sidebar li.parent .level1 > span {
}
.menu-sidebar li.parent.active .level1 > span {
    background-position: 100% -80px;
}
.mod-box-color .menu-sidebar li.parent .level1 > span, .mod-box-black .menu-sidebar li.parent .level1 > span {
    background-image: url(../images/menu_sidebar_parent_color.png);
}
/* Level 2-4 */
 .menu-sidebar ul.level2 {
    display:block;
    height:30px;
    padding-left: 10px;
    color:white;
    list-style-type: none!important
}
.menu-sidebar .level2 a {
    padding-left: 18px;
}
.mod-box-color .menu-sidebar .level2 a, .mod-box-black .menu-sidebar .level2 a {
    background-image: url(../images/menu_sidebar_level2_color.png);
}
.menu-sidebar .level2 a:hover {
    /*    color: rgb(143, 127, 69);
*/
}
/* Line Menu
----------------------------------------------------------------------------------------------------*/
 #toolbar .menu-line li {
    margin-left: 10px;
    padding-left: 12px;
}
#toolbar .menu-line li:hover {
    background-position: 0 -50px;
}
#toolbar .menu-line li:first-child {
    margin-left: 0;
}
.menu-line li {
    margin-left: 7px;
    padding-left: 8px;
    background: url(../images/menu_line.png) 0 50% no-repeat;
    text-transform: uppercase;
}
.menu-line li:first-child {
    margin-left: 0;
    padding-left: 0;
    background: none;
}
@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}
@-webkit-keyframes flip {
    0% {
        -webkit-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
        transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    40% {
        -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
        transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    50% {
        -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    80% {
        -webkit-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(.95);
        transform: perspective(400px) translateZ(0) rotateY(360deg) scale(.95);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    100% {
        -webkit-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
        transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
}
@keyframes flip {
    0% {
        -webkit-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
        -ms-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
        transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    40% {
        -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
        -ms-transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
        transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    50% {
        -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        -ms-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    80% {
        -webkit-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(.95);
        -ms-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(.95);
        transform: perspective(400px) translateZ(0) rotateY(360deg) scale(.95);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    100% {
        -webkit-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
        -ms-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
        transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
}
.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.animated.flip {
    -webkit-backface-visibility: visible;
    -ms-backface-visibility: visible;
    backface-visibility: visible;
    -webkit-animation-name: flip;
    animation-name: flip;
}
